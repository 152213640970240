/* eslint-disable */

const config = {
  production: {
    aws_project_region: 'eu-west-1',
    aws_cognito_identity_pool_id: 'eu-west-1:3577a0cd-9a89-48be-86c1-b7fe8a9d8a36',
    aws_cognito_region: 'eu-west-1',
    aws_user_pools_id: 'eu-west-1_CPYIMwbLB',
    aws_user_pools_web_client_id: '172en1fqcmvmkme4bflkqinefv',
    aws_user_files_s3_bucket: 'mos-mg-sync-apac1',
    aws_user_files_s3_bucket_region: 'ap-southeast-1',
    aws_content_delivery_bucket: 'mos-mg-sync-apac1',
    aws_content_delivery_bucket_region: 'ap-southeast-1',
    aws_upload_bucket: 'console-uploads',
    aws_appsync_graphqlEndpoint: 'https://b4wz4zyjqzaprijgajuk66sbkq.appsync-api.eu-west-1.amazonaws.com/graphql',
    aws_appsync_region: 'eu-west-1',
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
    server: 'https://svc01.elas.tech:7002',
    ws: 'wss://svc01.elas.tech:7002'
  },
  test: {
    aws_project_region: 'eu-west-1',
    aws_cognito_identity_pool_id: 'eu-west-1:ba2d04c2-3b83-4087-87eb-3c35e2f0a1ac',
    aws_cognito_region: 'eu-west-1',
    aws_user_pools_id: 'eu-west-1_sLDS4wNN3',
    aws_user_pools_web_client_id: '17os1vaisel91raeuk8qlt6kt8',
    aws_user_files_s3_bucket: 'mos-mg-sync-apac1',
    aws_user_files_s3_bucket_region: 'ap-southeast-1',
    aws_content_delivery_bucket: 'mos-mg-sync-apac1',
    aws_content_delivery_bucket_region: 'ap-southeast-1',
    aws_upload_bucket: 'console-uploads-test',
    aws_appsync_graphqlEndpoint: 'https://hlardicijfcpzppmdfscx2hcju.appsync-api.eu-west-1.amazonaws.com/graphql',
    aws_appsync_region: 'eu-west-1',
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
    server: 'https://svc01.elas.tech:7001',
    ws: 'wss://svc01.elas.tech:7001'
  },
  development: {
    // aws_project_region: 'eu-west-1',
    // aws_cognito_identity_pool_id: 'eu-west-1:cc8d0831-41ae-407a-9ac5-6c5bb3fa5141',
    // aws_cognito_region: 'eu-west-1',
    // aws_user_pools_id: 'eu-west-1_WNCPHbbhu',
    // aws_user_pools_web_client_id: 't86mjfc2k7us6mr4kbgaqgc7l',
    // aws_user_files_s3_bucket: 'mos-mg-sync-apac1',
    // aws_user_files_s3_bucket_region: 'ap-southeast-1',
    // aws_content_delivery_bucket: 'mos-mg-sync-apac1',
    // aws_content_delivery_bucket_region: 'ap-southeast-1',
    // aws_upload_bucket: 'console-uploads-dev',
    // aws_appsync_graphqlEndpoint: 'https://cny7lsgx3jay5cgzwushefh45u.appsync-api.eu-west-1.amazonaws.com/graphql',
    // aws_appsync_region: 'eu-west-1',
    // aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
    server: 'https://svc01.elas.tech:7001',
    ws: 'wss://svc01.elas.tech:7001',
    aws_project_region: 'eu-west-1',
    aws_cognito_identity_pool_id: 'eu-west-1:3577a0cd-9a89-48be-86c1-b7fe8a9d8a36',
    aws_cognito_region: 'eu-west-1',
    aws_user_pools_id: 'eu-west-1_CPYIMwbLB',
    aws_user_pools_web_client_id: '172en1fqcmvmkme4bflkqinefv',
    aws_user_files_s3_bucket: 'mos-mg-sync-apac1',
    aws_user_files_s3_bucket_region: 'ap-southeast-1',
    aws_content_delivery_bucket: 'mos-mg-sync-apac1',
    aws_content_delivery_bucket_region: 'ap-southeast-1',
    aws_upload_bucket: 'console-uploads',
    aws_appsync_graphqlEndpoint: 'https://b4wz4zyjqzaprijgajuk66sbkq.appsync-api.eu-west-1.amazonaws.com/graphql',
    aws_appsync_region: 'eu-west-1',
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  }
};

export default config;
